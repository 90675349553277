<template>
  <div class="flex flex-column orderlist">
    <van-sticky>
      <div class="flex flex-between orderlist-type">
        <div class="flex flex-center align-center orderlist-type-l" :class="orderType == 0 ? 'orderlist-type-l1' : ''" @click="changeOrderTypeFunc(0)">达人订单</div>
        <div class="flex flex-center align-center orderlist-type-l" :class="orderType == 1 ? 'orderlist-type-l1' : ''" @click="changeOrderTypeFunc(1)">普通订单</div>
      </div>
      <div class="flex flex-left orderlist-tab" v-show="orderType == 0">
        <div class="flex flex-center align-center orderlist-tab-list" v-for="(item,index) in tabDaPersonArr" :key="item.id" :class="currentDaPerson ==  index?'orderlist-tab-list1':''" @click="clickTab1(index)">
          {{item.name}}
          <i class="orderlist-tab-list-i" v-if="currentDaPerson ==  index"></i>
        </div>
      </div>
      <div class="flex flex-left orderlist-tab" v-show="orderType == 1">
        <div class="flex flex-center align-center orderlist-tab-list" v-for="(item,index) in tabArr" :key="item.id" :class="current ==  index?'orderlist-tab-list1':''" @click="clickTab(index)">
          {{item.name}}
          <i class="orderlist-tab-list-i" v-if="current ==  index"></i>
        </div>
      </div>
    </van-sticky>
    <!-- 达人 订单 列表 -->
    <van-list
      v-if="orderType == 0 && orderListData.length > 0"
      v-model:loading="loading"
      :finished="finished"
      :immediate-check="false"
      @load="getOrderListFunc"
    >
      <ul class="orderlist-main">
        <li class="orderlist-main-li" v-for="item in orderListData" :key="item.id" @click="goOrderDetail(item.orderNo)">
          <div class="flex flex-between orderlist-main-li-one">
            <span class="orderlist-main-li-one-l">
              订单：{{item.orderNo}}
            </span>
            <span class="orderlist-main-li-one-r">
              {{filterOrderStatusFunc(item.orderStatus)}}
            </span>
          </div>
          <div class="flex flex-left orderlist-main-li-two">
            <div class="orderlist-main-li-two-l">
              <img src="@/assets/img/order/kecheng.jpeg" alt="" style="width: 100%;height:100%;object-fit: scale-down;">
            </div>
            <div class="orderlist-main-li-two-r">
              <div class="orderlist-main-li-two-r-name">
                <div class="orderlist-main-li-two-r-name-tag" v-if="item.isSeckill == 1">限时抢购</div>流媒体营销密码与私域带货实战课程套装
              </div>
              <div class="orderlist-main-li-two-r-guige" style="padding: 10px;"></div>
              <div class="flex flex-between align-center orderlist-main-li-two-r-price">
                <div class="orderlist-main-li-two-r-price-d1">¥618</div>
                <span class="orderlist-main-li-two-r-price-s1">x1</span>
              </div>
            </div>
          </div>
          <div class="orderlist-main-li-three">
            共1件商品，<span>总计：￥618</span>
          </div>
          <div class="flex flex-right orderlist-main-li-four">
            <van-button v-if="item.orderStatus == 1" class="orderlist-main-li-four-btn" color="#E62129"
            round>去支付</van-button>
            <van-button class="orderlist-main-li-four-btn orderlist-main-li-four-btn1"
            v-if="item.orderStatus == 2" 
            plain
            round @click.stop="supplementBmInfoFunc(item.id)">补充报名信息</van-button>
            <van-button class="orderlist-main-li-four-btn orderlist-main-li-four-btn1"
            v-if="item.orderStatus != 1  && item.orderStatus != 4" 
            plain
            round @click.stop="readDhContentFunc">查看兑换内容</van-button>
          </div>
          <div class="flex flex-between align-center orderlist-main-li-five">
            <div class="orderlist-main-li-five-l">下单时间：{{item.gmtCreate}}</div>
            <div class="orderlist-main-li-five-r" v-if="item.orderStatus == 1"><span>{{item.min + ':' + item.sec}}</span>自动取消订单</div>
          </div>
        </li>
        <!-- 已经到底了 -->
        <toast-bottom v-if="finished"></toast-bottom>
      </ul>
    </van-list>
    <!-- 普通商品订单 列表 -->
    <van-list
      v-if="orderType == 1 && orderListData.length > 0"
      v-model:loading="loading"
      :finished="finished"
      :immediate-check="false"
      @load="getOrderListFunc"
    >
      <ul class="orderlist-main">
        <li class="orderlist-main-li" v-for="item in orderListData" :key="item.id" @click="goOrderDetail(item.id)">
          <div class="flex flex-between orderlist-main-li-one">
            <span class="orderlist-main-li-one-l">
              订单：{{item.orderNo}}
            </span>
            <span class="orderlist-main-li-one-r">
              {{filterOrderStatusFunc(item.orderStatus)}}
            </span>
          </div>
          <div class="flex flex-left orderlist-main-li-two" v-for="item1 in item.orderGoodItemList" :key="item1.id">
            <div class="orderlist-main-li-two-l" :style="{background: 'url('+item1.goodsImage+')no-repeat center center',backgroundSize: 'cover'}"></div>
            <div class="orderlist-main-li-two-r">
              <div class="orderlist-main-li-two-r-name">
                <div class="orderlist-main-li-two-r-name-tag" v-if="item.isSeckill == 1">限时抢购</div>{{item1.goodName}}
              </div>
              <div class="orderlist-main-li-two-r-guige">
                {{item1.specStr}}{{item1.specStr2}}
              </div>
              <div class="flex flex-between align-center orderlist-main-li-two-r-price">
                <div class="orderlist-main-li-two-r-price-d1">¥{{parseFloat(item1.promotionPrice/100).toFixed(2)}}<span>¥{{parseFloat(item1.originalPrice/100).toFixed(2)}}</span></div>
                <span class="orderlist-main-li-two-r-price-s1">x{{item1.goodNum}}</span>
              </div>
            </div>
          </div>
          <div class="orderlist-main-li-three">
            共{{item.number}}件商品，<span>总计：￥{{orderPriceFunc(item.goodAmount, item.couponCover, item.bonusCover, item.freightCharge)}}</span>（含运费 ¥{{parseFloat(item.freightCharge/100).toFixed(2)}}）
          </div>
          <div class="flex flex-right orderlist-main-li-four">
            <van-button v-if="item.orderStatus == 1" class="orderlist-main-li-four-btn" color="#E62129"
            round>去支付</van-button>
            <van-button class="orderlist-main-li-four-btn orderlist-main-li-four-btn1"
            v-if="item.orderStatus == 6" 
            plain
            round @click.stop="readLogisticsFunc(item.id)">查看物流</van-button>
            <van-button class="orderlist-main-li-four-btn orderlist-main-li-four-btn1"
            v-if="item.orderStatus == 6" 
            plain
            round color="#E62129">确认收货+1龙珠</van-button>
          </div>
        </li>
        <!-- 已经到底了 -->
        <toast-bottom v-if="finished"></toast-bottom>
      </ul>
    </van-list>
    <!-- 暂无数据 -->
    <div class="orderlist-nodata" v-if="orderListData.length == 0">
      <div class="orderlist-nodata-content">
        <div class="flex flex-center flex-wrap orderlist-nodata-content-msg">
          <img src="../../../assets/img/order/orderlist-nodata.png" alt="">
          <div>暂无订单</div>
        </div>
      </div>
    </div>

    <!-- 查看兑换内容 -->
    <qrToast :qrtoast="qrtoast"></qrToast>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>