import {
    getOrderList,
    shopServiceGoodOrderList
} from '@/utils/order.js';
import toastBottom from '@/components/bottom.vue';
import qrToast from '@/components/qr.vue'
import { provide, ref } from 'vue'
export default {
    components: { toastBottom, qrToast },
    setup() {
        let qrtoast = ref(false);
        provide('qrtoast', qrtoast);
        return {
            qrtoast
        }
    },
    data() {
        return {
            orderType: 0, // 0 达人订单  1 普通订单
            active: 0,
            tabArr: [{
                    name: '全部',
                    type: ''
                },
                {
                    name: '待支付',
                    type: 1
                },
                {
                    name: '待发货',
                    type: 2
                },
                {
                    name: '待收货',
                    type: 6
                },
                {
                    name: '已完成',
                    type: 5
                },
                {
                    name: '售后/退款',
                    type: 99
                }
            ],
            current: 0,
            tabDaPersonArr: [
                {
                    name: '全部',
                    type: ''
                },
                {
                    name: '待支付',
                    type: 1
                },
            ],
            currentDaPerson: 0,
            orderListData: [],
            page: 1,
            oldPage: 0,
            orderStatus: '',
            loading: false,
            finished: false,
        }
    },
    created() {
        // 跳转登录页面
        // if (!this.$cookies.get('isLogin')) {
        //     this.$router.push({
        //         path: '/login'
        //     })
        // } else {
            if (this.$route.query.orderStatus) {
                this.orderStatus = this.$route.query.orderStatus;
                this.current = this.$route.query.orderStatus;
                this.currentDaPerson = this.$route.query.orderStatus;
            }
            this.initPageFunc();
        // }
    },
    methods: {
        // 订单查询
        initPageFunc() {
            this.loading = false;
            this.finished = false;
            this.page = 1;
            this.oldPage = 0;
            this.orderListData = [];
            if (this.orderType == 0) {
                this.shopServiceGoodOrderListFunc();
            } else {
                this.getOrderListFunc();
            }
        },
        // 切换达人订单  普通订单
        changeOrderTypeFunc(type) {
            this.orderType = type;
            this.initPageFunc();
        },
        clickTab(idx) {
            this.current = idx;
            this.loading = false;
            this.finished = false;
            this.page = 1;
            this.oldPage = 0;
            this.orderStatus = this.tabArr[idx].type;
            this.orderListData = [];
            this.getOrderListFunc();
        },
        clickTab1(idx) {
            this.currentDaPerson = idx;
            this.loading = false;
            this.finished = false;
            this.page = 1;
            this.oldPage = 0;
            this.orderStatus = this.tabDaPersonArr[idx].type;
            this.orderListData = [];
            this.shopServiceGoodOrderListFunc();
        },
        // 获取 订单列表
        getOrderListFunc() {
            if (this.oldPage == this.page) return false;
            this.oldPage = this.page;
            let data = {
                memberId: this.$cookies.get('memberId'),
                pageNo: this.page,
                pageSize: 10,
                orderStatus: this.orderStatus // 1.待付款；2.待发货；3.待评价；4.售后中；5.订单完成；6.待收货；7.已取消；8.已关闭；9.已退款；10.已拒绝退款
            }
            getOrderList(data).then(res => {
                console.log('订单列表', res)
                let arr = res.data.result ? res.data.result : [];
                arr.forEach(item => {
                    this.orderListData.push(item)
                })
                this.loading = false;
                if (arr.length < 10) {
                    this.finished = true;
                } else {
                    this.page++;
                }
            })
        },
        // 获取达人 订单列表
        shopServiceGoodOrderListFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderStatus: this.orderStatus, // 99(查已履约)，订单状态：1.未支付；2.已支付；3.课程已领取；4.已取消
                pageNo: this.page,
                pageSize: 10
            }
            shopServiceGoodOrderList(data).then(res => {
                console.log('达人订单列表', res.data);
                let arr = res.data.result;
                arr.forEach(item => {
                    this.orderListData.push(item);
                })
                this.loading = false;
                if (arr.length < 10) {
                    this.finished = true;
                } else {
                    this.page++;
                }
                this.countDown();
            })
        },
        // 过滤订单状态
        filterOrderStatusFunc(status) {
            let name = '';
            if (this.orderType == 0) {
                switch (status) {
                    case 1:
                        name = '未支付';
                        break;
                    case 2:
                        name = '已支付';
                        break;
                    case 3:
                        name = '课程已领取';
                        break;
                    case 4:
                        name = '已取消';
                        break;
                }
            } else {
                switch (status) {
                    case 1:
                        name = '待付款';
                        break;
                    case 2:
                        name = '待发货';
                        break;
                    case 3:
                        name = '待评价';
                        break;
                    case 4:
                        name = '售后中';
                        break;
                    case 5:
                        name = '订单完成';
                        break;
                    case 6:
                        name = '待收货';
                        break;
                    case 7:
                        name = '已取消';
                        break;
                    case 8:
                        name = '已关闭';
                        break;
                    case 9:
                        name = '已退款';
                        break;
                    case 10:
                        name = '已拒绝退款';
                        break;
                    case 11:
                        name = '已确认收货';
                        break;
                }
            }
            return name;
        },
        // 跳转到订单详情
        goOrderDetail(orderId) {
            if (this.orderType == 0) {
                this.$router.push({
                    path: '/dhOrderDetail',
                    query: {
                        orderId: orderId
                    }
                })
            } else {
                this.$router.push({
                    path: '/orderDetail',
                    query: {
                        orderId: orderId
                    }
                })
            }
        },
        // 计算订单金额
        orderPriceFunc(goodsAmount, couponCover, bonusCover, freightCharge) {
            let aPrice = Number(goodsAmount - couponCover - bonusCover + freightCharge);

            return aPrice > 0 ? parseFloat(aPrice / 100).toFixed(2) : '0.00';
        },
        // 查看物流
        readLogisticsFunc(id) {
            this.$router.push({
                path: '/logisticsInfo',
                query: {
                    orderId: id
                }
            })
        },
        // 查看兑换内容
        readDhContentFunc() {
            this.qrtoast = true;
        },
        // 补充报名信息
        supplementBmInfoFunc(id) {
            this.$router.push({
                path: '/membersPaySuccess',
                query: {
                    orderId: id,
                    from: 2
                }
            })
        },
        // 补0
        timeFormat(param) {//小于10的格式化函数
            return param < 10 ? '0' + param : param;
        },
        countDown() {//倒计时函数
            var _that = this;
            // 获取当前时间，同时得到活动结束时间数组
            let newTime = new Date().getTime();
            let endTimeList = this.orderListData;
            let countDownArr = [];

            // 对结束时间进行处理渲染到页面
            endTimeList.forEach(o => {
                let endTime = new Date(o.gmtCreate).getTime() + 900000;
                // 如果活动未结束，对时间进行处理
                if (endTime - newTime > 0) {
                    let time = (endTime - newTime) / 1000;
                    // 获取分、秒
                    let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                    let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                    o['min'] = _that.timeFormat(min);
                    o['sec'] = _that.timeFormat(sec);
                } else {//活动已结束，全部设置为'00'
                    o['min'] = '00';
                    o['sec'] = '00';
                    if (o.orderStatus == 1) {
                        o.orderStatus = 4;
                    }
                }
                countDownArr.push(o);
            })
            // 渲染，然后每隔一秒执行一次倒计时函数 
            this.orderListData = countDownArr;
            setTimeout(_that.countDown, 1000);
        },
    },
}